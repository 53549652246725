/*
        font YeKan Bakh	
**/

@font-face {
  font-family: YekanBakh;
  font-style: normal;
  font-weight: 100;
  src: url('./fonts/eot/Yekan\ Bakh\ Fa\ 03\ Light.eot') format('eot'),
    url('./fonts/ttf/Yekan\ Bakh\ Fa\ 03\ Light.ttf') format('ttf'),
    url('./fonts/woff/Yekan\ Bakh\ Fa\ 03\ Light.woff') format('woff');
}

@font-face {
  font-family: YekanBakh;
  font-style: normal;
  font-weight: 200;
  src: url('./fonts/eot/Yekan\ Bakh\ Fa\ 04\ Regular.eot') format('eot'),
    url('./fonts/ttf/Yekan\ Bakh\ Fa\ 04\ Regular.ttf') format('ttf'),
    url('./fonts/woff/Yekan\ Bakh\ Fa\ 04\ Regular.woff') format('woff');
}

@font-face {
  font-family: YekanBakh;
  font-style: normal;
  font-weight: 300;
  src: url('./fonts/eot/Yekan\ Bakh\ Fa\ 05\ Medium.eot') format('eot'),
    url('./fonts/ttf/Yekan\ Bakh\ Fa\ 05\ Medium.ttf') format('ttf'),
    url('./fonts/woff/Yekan\ Bakh\ Fa\ 05\ Medium.woff') format('woff');
}

@font-face {
  font-family: YekanBakh;
  font-style: normal;
  font-weight: 400;
  src: url('./fonts/eot/Yekan\ Bakh\ Fa\ 06\ Bold.eot') format('eot'),
    url('./fonts/ttf/Yekan\ Bakh\ Fa\ 06\ Bold.ttf') format('ttf'),
    url('./fonts/woff/Yekan\ Bakh\ Fa\ 06\ Bold.woff') format('woff');
}

@font-face {
  font-family: YekanBakh;
  font-style: normal;
  font-weight: 500;
  src: url('./fonts/eot/Yekan\ Bakh\ Fa\ 06\ Bold.eot') format('eot'),
    url('./fonts/ttf/Yekan\ Bakh\ Fa\ 06\ Bold.ttf') format('ttf'),
    url('./fonts/woff/Yekan\ Bakh\ Fa\ 06\ Bold.woff') format('woff');
}

@font-face {
  font-family: YekanBakh;
  font-style: normal;
  font-weight: 600;
  src: url('./fonts/eot/Yekan\ Bakh\ Fa\ 07\ Heavy.eot') format('eot'),
    url('./fonts/ttf/Yekan\ Bakh\ Fa\ 07\ Heavy.ttf') format('ttf'),
    url('./fonts/woff/Yekan\ Bakh\ Fa\ 07\ Heavy.woff') format('woff');
}

@font-face {
  font-family: YekanBakh;
  font-style: normal;
  font-weight: 700;
  src: url('./fonts/eot/Yekan\ Bakh\ Fa\ 07\ Heavy.eot') format('eot'),
    url('./fonts/ttf/Yekan\ Bakh\ Fa\ 07\ Heavy.ttf') format('ttf'),
    url('./fonts/woff/Yekan\ Bakh\ Fa\ 07\ Heavy.woff') format('woff');
}

@font-face {
  font-family: YekanBakh;
  font-style: normal;
  font-weight: 800;
  src: url('./fonts/eot/Yekan\ Bakh\ Fa\ 07\ Heavy.eot') format('eot'),
    url('./fonts/ttf/Yekan\ Bakh\ Fa\ 07\ Heavy.ttf') format('ttf'),
    url('./fonts/woff/Yekan\ Bakh\ Fa\ 07\ Heavy.woff') format('woff');
}

@font-face {
  font-family: YekanBakh;
  font-style: normal;
  font-weight: bold;
  src: url('./fonts/eot/Yekan\ Bakh\ Fa\ 07\ Heavy.eot') format('eot'),
    url('./fonts/ttf/Yekan\ Bakh\ Fa\ 07\ Heavy.ttf') format('ttf'),
    url('./fonts/woff/Yekan\ Bakh\ Fa\ 07\ Heavy.woff') format('woff');
}

@font-face {
  font-family: YekanBakh;
  font-style: normal;
  font-weight: normal;
  src: url('./fonts/eot/Yekan\ Bakh\ Fa\ 06\ Bold.eot') format('eot'),
    url('./fonts/ttf/Yekan\ Bakh\ Fa\ 06\ Bold.ttf') format('ttf'),
    url('./fonts/woff/Yekan\ Bakh\ Fa\ 06\ Bold.woff') format('woff');
}

/* *
        font YeKan Bakh	Fa Num
**/

@font-face {
  font-family: YekanBakhFaNum;
  font-style: normal;
  font-weight: 100;
  src: url('./fonts/eot/Yekan\ Bakh\ FaNum\ 03\ Light.eot') format('eot'),
    url('./fonts/ttf/Yekan\ Bakh\ FaNum\ 03\ Light.ttf') format('ttf'),
    url('./fonts/woff/Yekan\ Bakh\ FaNum\ 03\ Light.woff') format('woff');
}

@font-face {
  font-family: YekanBakhFaNum;
  font-style: normal;
  font-weight: 200;
  src: url('./fonts/eot/Yekan\ Bakh\ FaNum\ 04\ Regular.eot') format('eot'),
    url('./fonts/ttf/Yekan\ Bakh\ FaNum\ 04\ Regular.ttf') format('ttf'),
    url('./fonts/woff/Yekan\ Bakh\ FaNum\ 04\ Regular.woff') format('woff');
}

@font-face {
  font-family: YekanBakhFaNum;
  font-style: normal;
  font-weight: 300;
  src: url('./fonts/eot/Yekan\ Bakh\ FaNum\ 05\ Medium.eot') format('eot'),
    url('./fonts/ttf/Yekan\ Bakh\ FaNum\ 05\ Medium.ttf') format('ttf'),
    url('./fonts/woff/Yekan\ Bakh\ FaNum\ 05\ Medium.woff') format('woff');
}

@font-face {
  font-family: YekanBakhFaNum;
  font-style: normal;
  font-weight: 400;
  src: url('./fonts/eot/Yekan\ Bakh\ FaNum\ 06\ Bold.eot') format('eot'),
    url('./fonts/ttf/Yekan\ Bakh\ FaNum\ 06\ Bold.ttf') format('ttf'),
    url('./fonts/woff/Yekan\ Bakh\ FaNum\ 06\ Bold.woff') format('woff');
}

@font-face {
  font-family: YekanBakhFaNum;
  font-style: normal;
  font-weight: 500;
  src: url('./fonts/eot/Yekan\ Bakh\ FaNum\ 06\ Bold.eot') format('eot'),
    url('./fonts/ttf/Yekan\ Bakh\ FaNum\ 06\ Bold.ttf') format('ttf'),
    url('./fonts/woff/Yekan\ Bakh\ FaNum\ 06\ Bold.woff') format('woff');
}

@font-face {
  font-family: YekanBakhFaNum;
  font-style: normal;
  font-weight: 600;
  src: url('./fonts/eot/Yekan\ Bakh\ FaNum\ 07\ Heavy.eot') format('eot'),
    url('./fonts/ttf/Yekan\ Bakh\ FaNum\ 07\ Heavy.ttf') format('ttf'),
    url('./fonts/woff/Yekan\ Bakh\ FaNum\ 07\ Heavy.woff') format('woff');
}

@font-face {
  font-family: YekanBakhFaNum;
  font-style: normal;
  font-weight: 700;
  src: url('./fonts/eot/Yekan\ Bakh\ FaNum\ 07\ Heavy.eot') format('eot'),
    url('./fonts/ttf/Yekan\ Bakh\ FaNum\ 07\ Heavy.ttf') format('ttf'),
    url('./fonts/woff/Yekan\ Bakh\ FaNum\ 07\ Heavy.woff') format('woff');
}

@font-face {
  font-family: YekanBakhFaNum;
  font-style: normal;
  font-weight: 800;
  src: url('./fonts/eot/Yekan\ Bakh\ FaNum\ 07\ Heavy.eot') format('eot'),
    url('./fonts/ttf/Yekan\ Bakh\ FaNum\ 07\ Heavy.ttf') format('ttf'),
    url('./fonts/woff/Yekan\ Bakh\ FaNum\ 07\ Heavy.woff') format('woff');
}

@font-face {
  font-family: YekanBakhFaNum;
  font-style: normal;
  font-weight: 900;
  src: url('./fonts/eot/Yekan\ Bakh\ FaNum\ 07\ Heavy.eot') format('eot'),
    url('./fonts/ttf/Yekan\ Bakh\ FaNum\ 07\ Heavy.ttf') format('ttf'),
    url('./fonts/woff/Yekan\ Bakh\ FaNum\ 07\ Heavy.woff') format('woff');
}

@font-face {
  font-family: YekanBakhFaNum;
  font-style: normal;
  font-weight: bold;
  src: url('./fonts/eot/Yekan\ Bakh\ FaNum\ 07\ Heavy.eot') format('eot'),
    url('./fonts/ttf/Yekan\ Bakh\ FaNum\ 07\ Heavy.ttf') format('ttf'),
    url('./fonts/woff/Yekan\ Bakh\ FaNum\ 07\ Heavy.woff') format('woff');
}

@font-face {
  font-family: YekanBakhFaNum;
  font-style: normal;
  font-weight: normal;
  src: url('./fonts/eot/Yekan\ Bakh\ FaNum\ 06\ Bold.eot') format('eot'),
    url('./fonts/ttf/Yekan\ Bakh\ FaNum\ 06\ Bold.ttf') format('ttf'),
    url('./fonts/woff/Yekan\ Bakh\ FaNum\ 06\ Bold.woff') format('woff');
}
